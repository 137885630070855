import { uouPageOpenedEvent } from '../../common/bi-events/uou-events/uou-page-opened-event';
import { uouPublishPostSuccessEvent } from '../../common/bi-events/uou-events/uou-publish-post-success-event';
import { uouCreateCommentSuccessEvent } from '../../common/bi-events/uou-events/uou-create-comment-success-event';
import { uouUpdateCommentSuccessEvent } from '../../common/bi-events/uou-events/uou-update-comment-success-event';
import { uouSavePostDraftSuccessEvent } from '../../common/bi-events/uou-events/uou-save-post-draft-success-event';
import { uouDeletePostSuccessEvent } from '../../common/bi-events/uou-events/uou-delete-post-success-event';
import { uouSetPostEditorPostEvent } from '../../common/bi-events/uou-events/uou-set-post-editor-post-event';
import { uouButtonClickedEvent } from '../../common/bi-events/uou-events/uou-button-clicked-event';
import { uouIncrementPostLikeCountEvent } from '../../common/bi-events/uou-events/uou-increment-post-like-count-event';
import { uouShowModalEvent } from '../../common/bi-events/uou-events/uou-show-modal-event';
import { uouHideModalEvent } from '../../common/bi-events/uou-events/uou-hide-modal-event';
import { uouTagClickedEvent } from '../../common/bi-events/uou-events/uou-tag-clicked-event';
import uouFileEvents from '../../common/bi-events/uou-events/uou-file-events';

export const eventMap = {
  ...uouPageOpenedEvent,
  ...uouPublishPostSuccessEvent,
  ...uouCreateCommentSuccessEvent,
  ...uouUpdateCommentSuccessEvent,
  ...uouSavePostDraftSuccessEvent,
  ...uouDeletePostSuccessEvent,
  ...uouSetPostEditorPostEvent,
  ...uouButtonClickedEvent,
  ...uouIncrementPostLikeCountEvent,
  ...uouShowModalEvent,
  ...uouHideModalEvent,
  ...uouFileEvents,
  ...uouTagClickedEvent,
};
