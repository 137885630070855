import { get } from 'lodash';
import { VIEW_MODE_EDITOR, VIEW_MODE_PREVIEW } from '@wix/communities-blog-client-common/dist/src/constants/view-modes';
import {
  fetchWixData,
  fetchWixDataSuccess,
} from '@wix/communities-blog-client-common/dist/src/store/wix-data/fetch-wix-data';

export const initWixData = (viewMode, dataKeys, config) => async dispatch => {
  if (viewMode === VIEW_MODE_EDITOR || viewMode === VIEW_MODE_PREVIEW) {
    return await dispatch(fetchWixData(dataKeys));
  } else {
    const values = dataKeys.reduce((acc, dataKey) => {
      acc[dataKey] = get(config, ['publicData', 'COMPONENT', dataKey], null);

      return acc;
    }, {});

    return await dispatch(fetchWixDataSuccess(values));
  }
};
